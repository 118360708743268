const comparing = document.querySelector('.project-single__compare__wrapper');
if (comparing) {
    window.addEventListener("load", function (e) {
        let active = false;
        document.querySelector('.project-single__compare__scroller').addEventListener('mousedown', function () {
            active = true;
            document.querySelector('.project-single__compare__scroller').classList.add('scrolling');
        });
        document.body.addEventListener('mouseup', function () {
            active = false;
            document.querySelector('.project-single__compare__scroller').classList.remove('scrolling');
        });
        document.body.addEventListener('mouseleave', function () {
            active = false;
            document.querySelector('.project-single__compare__scroller').classList.remove('scrolling');
        });

        document.body.addEventListener('mousemove', function (e) {
            if (!active) return;
            let x = e.pageX;
            x -= document.querySelector('.project-single__compare__wrapper').getBoundingClientRect().left;
            scrollIt(x);
        });

        function scrollIt(x) {
            let transform = Math.max(0, (Math.min(x, document.querySelector('.project-single__compare__wrapper').offsetWidth)));
            document.querySelector('.project-single__compare__after').style.width = transform + "px";
            document.querySelector('.project-single__compare__scroller').style.left = transform - 25 + "px";
        }

        scrollIt(150);

        document.querySelector('.project-single__compare__scroller').addEventListener('touchstart', function () {
            active = true;
            document.querySelector('.project-single__compare__scroller').classList.add('scrolling');
        });
        document.body.addEventListener('touchend', function () {
            active = false;
            document.querySelector('.project-single__compare__scroller').classList.remove('scrolling');
        });
        document.body.addEventListener('touchcancel', function () {
            active = false;
            document.querySelector('.project-single__compare__scroller').classList.remove('scrolling');
        });
    });
}