// BURGER MENU //

var menu__button = document.querySelector(".main-navigation__toggle");
var burger = document.querySelector(".menu-toggle");
var body = document.querySelector("body");

if(burger) {
    burger.addEventListener("click", function(){
        this.classList.toggle("active");
    })
}

if(menu__button) {
    menu__button.addEventListener("click", function(e){
        var menu = document.querySelector(".main-navigation__mobile");
        if (menu.classList.contains('main-navigation__mobile--inactive')){
            menu.classList.add('main-navigation__mobile--active')
            menu.classList.remove('main-navigation__mobile--inactive')
            document.body.style.overflowY = "hidden"
        }
        else if(menu.classList.contains('main-navigation__mobile--active')){
            menu.classList.add('main-navigation__mobile--inactive')
            menu.classList.remove('main-navigation__mobile--active')
            document.body.style.overflowY = "visible"
        }
    });
}