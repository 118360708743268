var poppin = document.querySelector('.poppin-message');
var container = document.querySelector('.contact-container');
var container2 = document.querySelector('.contact-container2');

if(poppin) {
    window.addEventListener("load", function(e){
        container.classList.add('blurred');
        container2.classList.add('blurred');
        document.body.classList.add('freeze');
    });
}
else if(poppin){
    window.addEventListener("load", function(e){
        container.classList.remove('blurred');
        container2.classList.remove('blurred');
        document.body.classList.remove('freeze');
    });
}


var cookie = document.cookie;
if (cookie.indexOf('visited=', 0) == -1) {
    var expiration = new Date();
    expiration.setDate(expiration.getDate()+1);
    document.cookie = 'visited=1;expires=' + expiration + ';path=/';

    var element = document.querySelector('.category-banneer');
    var cookies = document.querySelector('.cookies-banneer');
    element.classList.add('category-banneer--active');
    element.classList.remove('category-banneer--inactive');
    cookies.classList.add('cookies-banneer--active');
    cookies.classList.remove('cookies-banneer--inactive');
    document.body.style.overflow = "hidden"
}
var closePop = document.querySelector(".category-banneer__close");
var closeCook = document.querySelector(".cookies-banneer__close");
var close__toggle = document.querySelector(".close-toggle");
var close__toggleBis = document.querySelector(".close-togglebis");
var body = document.querySelector("body");
if(closePop) {
    closePop.addEventListener("click", function(){
        this.classList.toggle("active");
    })
}
if(close__toggle) {
    close__toggle.addEventListener("click", function(e){
        var element = document.querySelector('.category-banneer');
        if (element.classList.contains('category-banneer--active')){
            element.classList.add('category-banneer--inactive')
            element.classList.remove('category-banneer--active')
            document.body.style.overflow = "visible"
        }
    });
}
if(closeCook) {
    closeCook.addEventListener("click", function(){
        this.classList.toggle("active");
    })
}
if(close__toggleBis) {
    close__toggleBis.addEventListener("click", function(e){
        var element = document.querySelector('.cookies-banneer');
        if (element.classList.contains('cookies-banneer--active')){
            element.classList.add('cookies-banneer--inactive')
            element.classList.remove('cookies-banneer--active')
        }
    });
}