const swiperWrap = document.querySelector('.swiper-container');
if (swiperWrap) {
    var swiper = new Swiper('.swiper-container', {
        slidesPerView: 2,
        spaceBetween: 40,
        loop: true,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: true,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.home-projects__controls__next',
            prevEl: '.home-projects__controls__prev',
        },
        breakpoints: {
            1024: {
                slidesPerView: 1,
                spaceBetween: 40,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            }
        }
    });
}